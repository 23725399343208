export const getSceneType = (scene, storeScenetype) => {
    let sceneType, sceneImageUrl;

    switch (storeScenetype) {
        case "desert":
            sceneType = "sceneImage1";
            if (scene) sceneImageUrl = scene.sceneImage1;
            break;
        case "night":
            sceneType = "sceneImage2";
            if (scene) sceneImageUrl = scene.sceneImage2;
            break;
        case "interior":
            sceneType = "sceneImage3";
            if (scene) sceneImageUrl = scene.sceneImage3;
            break;
    }

    return { sceneType, sceneImageUrl };
};

export const getCategoryName = (lensId, storeData) => {
    for (const category of storeData) {
        for (const lens of category.lenses) {
            if (lens.id === lensId) {
                return category.name;
            }
        }
    }

    return "";
};

export const requestFullScreen = (element) => {
    if (element.requestFullscreen) {
        element.requestFullscreen();
    } else if (element.mozRequestFullScreen) {
        // Firefox
        element.mozRequestFullScreen();
    } else if (element.webkitRequestFullscreen) {
        // Chrome, Safari, and Opera
        element.webkitRequestFullscreen();
    } else if (element.msRequestFullscreen) {
        // IE/Edge
        element.msRequestFullscreen();
    } else if (element.webkitEnterFullscreen) {
        element.webkitEnterFullscreen(); //for iphone this code worked
    }
};


