<template>
  <div class="carouselContainer">
    <Carousel
      :items-to-show="1"
      :wrap-around="true"
      :autoplay="6000"
      ref="myCarousel"
    >
      <Slide v-for="image in images" :key="image" @click="setNextSlide">
        <div class="carousel__item">
          <img :src="image.path" alt="" class="carouselImg" />
        </div>
      </Slide>

      <template #addons>
        <Navigation />
        <Pagination />
      </template>
    </Carousel>
    <p class="cine-info">
      CINEFLARES Pro is the largest library of professional cine lenses,
      comparing their lens flare characteristics in a controlled environment.
      Shot with motion control on large format cameras.
    </p>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { Carousel, Navigation, Slide, Pagination } from "vue3-carousel";

import "vue3-carousel/dist/carousel.css";

const myCarousel = ref(null);

const setNextSlide = () => {
  myCarousel.value.next();
};

const images = [
  { path: require(`@/assets/img/carousel/carousel01.webp`) },
  { path: require(`@/assets/img/carousel/carousel02.webp`) },
  { path: require(`@/assets/img/carousel/carousel03.webp`) },
  { path: require(`@/assets/img/carousel/carousel04.webp`) },
  { path: require(`@/assets/img/carousel/carousel05.webp`) },
];
</script>

<style>
.carouselContainer {
  display: flex;
  flex-direction: column;
  margin-right: 3%;
  align-items: center;
  justify-content: center;
}
.carousel__item {
  background-color: var(--vc-clr-primary);
  color: var(--vc-clr-white);
  font-size: 20px;
  border-radius: 8px;
  display: flex;
  cursor: pointer;
}

.carousel__pagination {
  justify-content: flex-start;
  padding: 2rem 0 1rem 0;
}
.carousel__icon {
  display: none;
}

.carouselImg {
  width: 100%;
  height: auto;
}

.carousel__prev,
.carousel__next {
  display: none;
}

.carousel__pagination-button::after {
  background-color: gray;
}

.carousel__pagination-button--active::after {
  background-color: white;
}
.carousel__pagination-button:hover::after .carousel__prev,
.carousel__next {
  box-sizing: content-box;
  border: 5px solid white;
}

.carousel__pagination-button:hover::after {
  background: gray;
}

@media only screen and (max-width: 768px) {
  .carouselContainer {
    width: 100%;
    margin: 10px;
  }
}
</style>
