/*eslint-disable */
import { createApp } from "vue";
import App from "./App.vue";
import { createPinia } from "pinia";
import VueSidebarMenu from "vue-sidebar-menu";
import VeeValidatePlugin from "@/includes/validation";
import "@fortawesome/fontawesome-free/css/all.css";
import VueEasyLightbox from "vue-easy-lightbox";

import "vue-sidebar-menu/dist/vue-sidebar-menu.css";

import router from "./routes";
import { auth } from "./firebase/init";

import { lineSpinner } from "ldrs";

lineSpinner.register();

let app;

const pinia = createPinia();

auth.onAuthStateChanged(() => {
  if (!app) {
    app = createApp(App);
    app.use(pinia);
    app.use(router);
    app.use(VueSidebarMenu);
    app.use(VeeValidatePlugin);
    app.use(VueEasyLightbox);

    app.mount("#app");
  }
});
