import client from "./client";

export const getSceneBgSrc = async (categoryId, focalLength) => {
  try {
    const response = await client.get(
      `/scene-image?lensCategoryId=${categoryId}&focalLength=${focalLength}`
    );

    return response;
  } catch (err) {
    const { response } = err;

    if (response?.data) return response.data;

    return { error: err.message || err };
  }
};
