<template>
  <div class="aboutpage">
    <div class="aboutcontainer">
      <div class="column left"></div>
      <div class="column middle">
        <div class="logo-site">
          <img :src="userStore.logoSource" alt="cineflares" />
        </div>
      </div>
      <div class="column right"></div>
    </div>
    <div style="display: flex">
      <div class="column left"></div>
      <section id="about-container">
        <div class="about-div">
          <h3>{{ aboutData?.title || "About" }}</h3>
          <div class="secondary-paragraph" v-html="aboutData?.content"></div>
        </div>
      </section>
      <div class="column right"></div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, onUnmounted, ref } from "vue";
import "../styles/About.css";
import { useUsersStore } from "@/stores/Users";
import { getAboutDetails } from "@/api/about";
import { useRouter } from "vue-router";
const router = useRouter();

const aboutData = ref();

const userStore = useUsersStore();

const fetchAboutDetails = async () => {
  const data = await getAboutDetails();

  if (data.length === 0 || data?.error) {
    router.push("/");
  }

  aboutData.value = data[0];
};

onMounted(async () => {
  document.querySelector("body").classList.add("backgroundImage");
  await fetchAboutDetails();
});
onUnmounted(() => {
  document.querySelector("body").classList.remove("backgroundImage");
});
</script>
