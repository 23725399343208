<template>
  <div class="search-box">
    <div class="search-input-field" :style="calculateSearchStyle()">
      <div class="search-icon">
        <img
          :src="
            props.isScene === true
              ? require('../assets/img/search_icon_black.png')
              : require('../assets/img/search_icon.png')
          "
          alt=""
        />
      </div>
      <input
        type="text"
        class="search-input"
        @focus="handleSearchFocus"
        @blur="handleSearchBlur"
        v-model="searchKeyword"
        :style="{
          backgroundColor: props.isScene === true && '#EBEBEB',
          color: props.isScene === true && 'black',
        }"
      />
    </div>
    <div
      class="search-result-box"
      :style="calculateSearchResultStyles()"
      v-if="showSearchResults"
    >
      <div class="search-results">
        <div v-if="loading" class="loading-div">
          <l-line-spinner
            size="20"
            stroke="2"
            speed="1"
            color="rgba(255,255,255,0.5)"
          ></l-line-spinner>
        </div>
        <div v-else>
          <div v-if="searchKeyword === '' && searchData.length === 0">
            Enter your keyword
          </div>
          <div v-if="searchData.length > 0">
            <div
              class="search-result"
              v-for="search in searchData"
              :key="search"
            >
              <div class="search-head">{{ search.categoryName }}</div>
              <div
                class="search-data"
                v-for="lenses in search.filteredLenses"
                :key="lenses.name"
                @click="handleSearchDataClick(lenses.name)"
              >
                <span>{{ lenses.name }}</span>
                <span
                  v-if="lenses.isNew"
                  class="new-badge"
                  style="font-size: 10px"
                  >new</span
                >
              </div>
            </div>
          </div>
          <div v-if="searchKeyword !== '' && searchData.length === 0">
            No results found.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import client from "@/api/client";
import { useLensesStore } from "@/stores/Lenses";
import { useScenesStore } from "@/stores/Scenes";
import { defineProps, defineEmits, ref, watch } from "vue";

const props = defineProps(["isLeftSearch", "isScene", "isBrowse"]);
const lensStore = useLensesStore();
const sceneStore = useScenesStore();

const searchData = ref([]);
const debounceTimer = ref(null);

const emits = defineEmits(["focusEvent", "blurEvent", "searchDataClick"]);

const searchKeyword = ref("");
const showSearchResults = ref(false);
const loading = ref(false);

const calculateSearchStyle = () => {
  if (props.isBrowse) {
    return {
      marginLeft: "0px",
      marginRight: "0px",
    };
  }

  return {
    marginLeft: props.isScene ? "8px" : props.isLeftSearch ? "48px" : "28px",
    marginRight: props.isScene ? "8px" : "",
  };
};

const calculateSearchResultStyles = () => {
  if (props.isBrowse || props.isScene) {
    return {
      marginLeft: "0px",
      marginRight: "0px",
      width: "100%",
    };
  }

  return {
    marginLeft: props.isLeftSearch ? "48px" : "28px",
    marginRight: "0px",
  };
};

const handleSearchFocus = () => {
  emits("focusEvent");

  showSearchResults.value = true;
  if (props.isScene) {
    sceneStore.searchIsActive = true;
  } else {
    lensStore.searchIsActive = true;
  }
};

const handleSearchBlur = () => {
  emits("blurEvent");

  setTimeout(() => {
    if (!props.isBrowse) {
      searchKeyword.value = "";
      searchData.value = [];
    }

    showSearchResults.value = false;
    if (props.isScene) {
      sceneStore.searchIsActive = false;
    } else {
      lensStore.searchIsActive = false;
    }
  }, 200);
};

const removeDublicates = (data) => {
  let lensDict = {};

  // Iterate through the array
  data.forEach((lens) => {
    if (!lensDict[lens.name]) {
      // If lens name not in dict, add it
      lensDict[lens.name] = { name: lens.name, isNew: lens.isNew };
    } else {
      // If lens name is already in dict, update isNew state
      if (lens.isNew) {
        lensDict[lens.name].isNew = true;
      }
    }
  });

  // Convert the dictionary back to an array
  let uniqueLenses = Object.values(lensDict);

  return uniqueLenses;
};

const handleSearchDataClick = (name) => {
  if (props.isBrowse) {
    emits("searchDataClick", name);
    searchKeyword.value = name.toLowerCase();
    return;
  }

  if (props.isScene) {
    if (props.isLeftSearch) {
      sceneStore.setLeftSelectedVideoId(
        name.toLowerCase().replace(/\s+/g, "-") + "-left"
      );
    } else {
      sceneStore.setRightSelectedVideoId(
        name.toLowerCase().replace(/\s+/g, "-") + "-right"
      );
    }
  } else {
    if (props.isLeftSearch) {
      lensStore.setLeftSelectedVideoId(
        name.toLowerCase().replace(/\s+/g, "-") + "-left"
      );
    } else {
      lensStore.setRightSelectedVideoId(
        name.toLowerCase().replace(/\s+/g, "-") + "-right"
      );
    }
  }

  searchKeyword.value = "";
};

const searchLens = async () => {
  const endPoint = props.isScene
    ? "/lens/scenes/search?searchQuery="
    : "/lens/search?query=";
  try {
    const response = await client.get(
      `${process.env.VUE_APP_BASE_URL}${endPoint}${searchKeyword.value}`
    );

    let unfilteredArray = [];

    unfilteredArray = props.isScene ? response.data : response.data.lens;
    let filteredArray = [];

    unfilteredArray.forEach((array) => {
      const lensObject = array.lenses?.map((lens) => {
        return { name: lens.name, isNew: lens.isNew };
      });
      const filteredLenses = removeDublicates(lensObject);

      filteredArray.push({
        categoryName: array.name,
        filteredLenses,
      });
    });

    searchData.value = filteredArray;
  } catch (err) {
    console.log(err);
  } finally {
    loading.value = false;
  }
};

watch(searchKeyword, async () => {
  emits("searchDataClick", searchKeyword.value);

  if (!searchKeyword.value || searchKeyword.value === "") {
    searchData.value = [];
    return;
  }
  if (debounceTimer.value) clearTimeout(debounceTimer.value);

  loading.value = true;

  debounceTimer.value = setTimeout(() => {
    searchLens();
  }, 1000);
});
</script>

<style scoped>
.search-data {
  display: flex;
  gap: 5px;
  align-items: center;
}

.new-badge {
  height: fit-content;
}

.scene-new-data {
  font-size: 12px;
}

.search-scene-data {
  font-size: 12px;
  display: flex;
  align-items: center;
}

.search-scene-data .new-badge {
  height: fit-content;
}

@media screen and (max-width: 720px) {
  .search-input-field {
    max-width: none;
  }

  .search-input {
    width: 100%;
    background: rgba(51, 152, 219, 0.2);
    padding: 12px 60px 12px 20px;
    border: none;
    outline: none;
    border-radius: 30px;
    font-size: 16px;
  }

  .search-result-box {
    width: 100%;
    max-width: none;
  }
}
</style>
