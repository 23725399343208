<template>
  <div id="formPage">
    <section>
      <div class="form-container">
        <h3 class="form-heading"><span>SIGN IN</span></h3>

        <div class="form-box">
          <form
            class="form"
            id="signUpEmailForm"
            ref="signUpEmailForm"
            @submit.prevent="login()"
          >
            <div class="form-row">
              <input type="text" placeholder="Email" v-model="email" />
            </div>
            <div class="form-row password-form-row">
              <input
                type="password"
                placeholder="Password"
                v-model="password"
              />
            </div>
            <div class="forgot-pass-btn">
              <span @click="resetPassword" class="form-link"
                >Reset Password</span
              >
            </div>

            <button type="submit">Login</button>
          </form>
          <div class="form-message">
            <p>
              Don't have an account?
              <span class="form-link" @click="redirectToSignUp">Sign Up</span>
            </p>
          </div>
        </div>
      </div>
      <div class="login-intro-section">
        <div class="intro-paragraph">
          <p class="landing-paragraph">
            Your interactive lens flare library, offering you access to our
            professional lens flare testing tool.
          </p>
          <p class="landing-paragraph">
            Join CINEFLARES and explore our interactive lens flare library.
            <br />
            Compare professionally shot lens flares in a unique motion
            controlled environment and discover new lenses in a meshmerizing
            beautiful way.
          </p>
        </div>
        <div class="landing-preview">
          <img src="@/assets/img/landing-laptop-preview.webp" alt="" />
        </div>
      </div>
    </section>
  </div>
</template>

<script setup>
import { signInWithEmailAndPassword } from "@firebase/auth";
import { auth } from "../firebase/init.js";
import { onMounted, onUnmounted, ref } from "vue";
import { useUsersStore } from "@/stores/Users";
import { useOverlayStore } from "@/stores/Overlay";
const overlayStore = useOverlayStore();

import { useRouter } from "vue-router";
import { useToastStore } from "@/stores/Toast";
import { useWishlistStore } from "@/stores/Wishlist.js";

const wishlistStore = useWishlistStore();
const email = ref("");
const password = ref("");
const store = useUsersStore();
const toastStore = useToastStore();
const router = useRouter();

const redirectToSignUp = () => {
  router.push("/signup");
};

onMounted(function () {
  document.querySelector("body").classList.add("formBody");
});

onUnmounted(() => {
  document.querySelector("body").classList.remove("formBody");
});

const login = async () => {
  overlayStore.showLoadingOverlay();
  try {
    const response = await signInWithEmailAndPassword(
      auth,
      email.value,
      password.value
    );
    if (response.user.accessToken) {
      store.updateLoginStatus(response);
      router.push("/");
      await wishlistStore.fetchWishlist();
    }

    overlayStore.hideLoadingOverlay();

    return;
  } catch (err) {
    toastStore.showToast("error", "Invalid credentials!");
    overlayStore.hideLoadingOverlay();
  }
};

const resetPassword = async () => {
  router.push("/reset-password");
};
</script>

<style scoped>
.password-form-row {
  margin-bottom: 5px;
}
.forgot-pass-btn {
  display: inline-block;
  text-align: right;
  margin-bottom: 20px;
}
.forgot-pass-btn span {
  font-size: 13px;
}

.form-link {
  color: white;
  text-decoration: underline;
  cursor: pointer;
}

.form-message {
  font-size: 13px;
  text-align: center;
}
.landing-preview {
  max-width: 400px;
  margin: 0 20px;
  margin-top: 20px;
}
.landing-preview img {
  width: 100%;
}
.login-intro-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 50px;
}
.landing-paragraph {
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 5px;
  margin-top: 10px;
  line-height: 30px;
  max-width: 700px;
}
.privacy-disclaimer {
  color: rgba(88, 87, 87, 0.8);
  font-size: 16px;
}
.form-box {
  margin-bottom: 40px;
}
</style>
