<template>
  <div class="secondary-body">
    <div class="container">
      <div class="column left"></div>
      <div class="column middle">
        <div class="site-logo">
          <img :src="userStore.logoSource" alt="cineflares" />
        </div>
      </div>
      <div class="column right"></div>
    </div>
    <section id="news-section">
      <div class="column left"></div>
      <div class="comumn middle" v-if="NewsStore.news">
        <div class="news-container">
          <h3 class="secondary-title">News</h3>
          <NewsComponent
            v-for="(newsItem, index) in NewsStore.news"
            :key="index"
            :title="newsItem.title"
            :description="newsItem.description"
            :date="newsItem.createdAt"
            :imageUrl="newsItem.imageUrl"
          />
        </div>
      </div>
      <div class="column right"></div>
    </section>
  </div>
</template>

<script setup>
import "../styles/News.css";
import NewsComponent from "../components/NewsComponent.vue";
import { onBeforeMount, onUnmounted } from "vue";
import { useNewsStore } from "../stores/News";
import { useUsersStore } from "@/stores/Users";

const userStore = useUsersStore();

const NewsStore = useNewsStore();
onBeforeMount(async () => {
  await NewsStore.fetchNews();
  document.querySelector("body").classList.add("backgroundImage");
});

onUnmounted(() => {
  document.querySelector("body").classList.remove("backgroundImage");
});
</script>
