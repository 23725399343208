<template>
  <div autofocus>
    <div
      class="loading-spinner-overlay"
      id="loading-spinner-overlay"
      ref="loadingSpinnerRef"
      style="display: none"
    >
      <div class="loading-spinner"></div>
    </div>
    <h2 class="scene-title">Scene preview</h2>

    <MobileSearch :isScene="true" />

    <div class="heading">
      <div class="icon">
        <img style="display: none" id="iconImage" ref="iconRef" />
      </div>
      <div class="focalLength">
        <select
          id="focalLength"
          name="focalLength"
          ref="focalLengthRef"
          style="display: none"
          @change="setFocalLengthChange($event)"
        >
          <option
            v-for="option in store.focalLengthOptionsRight"
            :key="option"
            :value="option"
          >
            {{ option }}
          </option>
        </select>
      </div>
      <div class="transmission">
        <select
          id="transmission"
          name="transmission"
          ref="transmissionRef"
          style="display: none"
          @change="setTransmissionChange($event)"
        >
          <option
            v-for="option in store.transmissionsRight"
            :key="option"
            :value="option"
          >
            {{ option }}
          </option>
        </select>
      </div>
    </div>
    <div>
      <div class="slider-container" ref="sliderContainer">
        <div class="spinner-container" v-if="sliderLoading">
          <div class="spinner-load"></div>
        </div>

        <SaveBtn
          :status="saveBtnStatus"
          :loading="saveBtnLoading"
          @click="addWishlist"
          :viewCondition="!isFullScreen"
          :sceneSave="{ isScene: true, isLeft: false }"
        />
        <ImgComparisonSlider
          style="display: block"
          class="image-comparison-slider"
        >
          <!-- eslint-disable -->

          <img
            slot="first"
            style="width: 100%; aspect-ratio: 2.4 / 1"
            ref="sceneBackgroundRef"
            src="@/assets/img/scene_load.png"
            class="fade-in-image"
          />

          <svg
            width="25"
            slot="handle"
            viewBox="0 0 425 614"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="425" height="614" rx="212" fill="white" />
            <path
              d="M146 202.5V412.5C146 422.16 138.16 430 128.5 430C118.84 430 111 422.16 111 412.5V202.5C111 192.84 118.84 185 128.5 185C138.16 185 146 192.84 146 202.5ZM195 202.5V412.5C195 422.16 202.84 430 212.5 430C222.16 430 230 422.16 230 412.5V202.5C230 192.84 222.16 185 212.5 185C202.84 185 195 192.84 195 202.5ZM279 202.5V412.5C279 422.16 286.84 430 296.5 430C306.16 430 314 422.16 314 412.5V202.5C314 192.84 306.16 185 296.5 185C286.84 185 279 192.84 279 202.5Z"
              fill="black"
            />
          </svg>

          <img
            slot="second"
            style="width: 100%; aspect-ratio: 2.4 / 1"
            ref="sceneLensRef"
            src="@/assets/img/scene_load.png"
            class="fade-in-image"
          />

          <!-- eslint-enable -->
        </ImgComparisonSlider>

        <div
          class="scene-fullscreen"
          @click="requestSceneFullscreen()"
          v-if="canFullScreen"
        >
          <img
            src="@/assets/img/fullscreen.svg"
            width="12"
            v-if="!isFullScreen"
          />
          <img src="@/assets/img/exit-fullscreen.svg" width="15" v-else />
        </div>
      </div>
    </div>
    <div class="scene-control-wrapper scene-control-mobile">
      <!-- <div class="scene-control-left">
              <div style="padding-bottom: 12px">
                <span>Aspect Ratio</span>
                <span class="scene-prop">2.39:1</span>
                <span class="scene-prop prop-active">2:1</span>
                <span class="scene-prop">1.78:1</span>
              </div>
              <div>
                <span>Shooting Format</span>
                <span class="scene-prop">S35</span>
                <span class="scene-prop prop-active">FF</span>
              </div>
            </div> -->
      <div class="scene-control-right">
        <span
          class="scene-tab"
          :class="{ 'tab-active': store.sceneType === 'desert' }"
          @click="setSceneType('desert')"
          >DESERT</span
        >
        <CustomTooltip text="Coming Soon">
          <span
            class="scene-tab"
            :class="{ 'tab-active': store.sceneType === 'night' }"
            @click="setSceneType('night')"
            >NIGHT</span
          >
        </CustomTooltip>

        <CustomTooltip text="Coming Soon">
          <span
            class="scene-tab"
            :class="{ 'tab-active': store.sceneType === 'interior' }"
            @click="setSceneType('interior')"
            >INTERIOR</span
          >
        </CustomTooltip>
      </div>
    </div>
    <div class="lenslist-container">
      <div class="lensesList">
        <ul
          class="viewport"
          ref="viewport"
          @scroll="() => store.snapToClosestOption(viewport, true)"
        >
          <li v-for="item in store.data" :key="item.id">
            <a class="nav-title nav-entity">{{ item.name }}</a>
            <ul class="nested-list" v-if="item.lenses && item.lenses.length">
              <li
                v-for="lens in uniqueLenses(item.lenses)"
                :key="getLinkId(lens.name)"
                class="nav-item nav-entity"
              >
                <a
                  @click="setSelection(lens.name)"
                  :id="getLinkId(lens.name)"
                  href="javascript:;"
                  class="nav-link"
                  >{{ lens.name }}</a
                >
                <span class="new-badge" v-if="lens.isNew">NEW</span>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, watch, ref, onUnmounted, computed } from "vue";
import { storeToRefs } from "pinia";
import { useScenesStore } from "@/stores/Scenes";
import MobileSearch from "../MobileComponents/MobileSearch.vue";
import "../../styles/MobileComponents/MiddleContainer.css";
import { ImgComparisonSlider } from "@img-comparison-slider/vue";
import { addToWishlist, removeFromWishlist } from "@/api/wishlist";
import SaveBtn from "../Selects/SaveBtn.vue";
import {
  getCategoryName,
  getSceneType,
  requestFullScreen,
} from "@/includes/scene";
import { useWishlistStore } from "@/stores/Wishlist";
import CustomTooltip from "../CustomTooltip.vue";

const ENABLE_SCENE_CHANGE = false;

const store = useScenesStore();
const focalLengthRef = ref(null);
const transmissionRef = ref(null);
const iconRef = ref(null);
const loadingSpinnerRef = ref(null);
const viewport = ref(null);
const singleVideoDetail = ref(null);
const saveBtnStatus = ref(null);
const saveBtnLoading = ref(false);
const sliderLoading = ref(false);
const sceneBackgroundRef = ref(null);
const sceneLensRef = ref(null);
const sliderContainer = ref(null);
const isFullScreen = ref(false);
const canFullScreen = ref(null);

const { SelectedVideo, SelectedFocalLength, SelectedTransmission, sceneType } =
  storeToRefs(store);

const wishlistStore = useWishlistStore();

const sceneWishlist = computed(() =>
  wishlistStore.wishlist.filter((item) => item.sceneImageUrl)
);

const handleUnsave = async (lensId) => {
  let currentScene = getSceneType("", store.sceneType);

  const wishlistData = sceneWishlist.value.find(
    (wishlist) =>
      wishlist.lensId === lensId &&
      wishlist.sceneImageType === currentScene.sceneType
  );

  if (!wishlistData) return;
  await removeFromWishlist(wishlistData.id);

  wishlistStore.removeFromWishlist(wishlistData.id);

  saveBtnLoading.value = false;
};

const addWishlist = async () => {
  let status;
  let lensCategoryName;

  saveBtnLoading.value = false;
  status = saveBtnStatus.value;

  lensCategoryName = store.data.find(
    (lens) => lens.id === singleVideoDetail.value?.lensCategoryId
  )?.name;

  const selectedScene = singleVideoDetail.value;

  lensCategoryName = getCategoryName(selectedScene.id, store.data);

  const {
    id: lensId,
    name,
    focalLength,
    transmission,
    videoPath,
  } = selectedScene;

  saveBtnLoading.value = true;

  if (status === "saved") {
    handleUnsave(lensId);
    return;
  }

  const payload = {
    lensId,
    name,
    focalLength,
    transmission,
    sceneImageType: getSceneType(selectedScene, store.sceneType).sceneType,
    sceneImageUrl: getSceneType(selectedScene, store.sceneType).sceneImageUrl,
    videoPath,
    categoryName: lensCategoryName,
  };

  const response = await addToWishlist(payload);

  if (response.status === 200) {
    wishlistStore.addToWishlist(response.data);
  }

  saveBtnLoading.value = false;
};

const generateSaveIcon = () => {
  if (!singleVideoDetail.value) return;
  const { id } = singleVideoDetail.value;

  let idArray = [];

  sceneWishlist.value?.forEach((obj) => {
    idArray.push({ lensId: obj.lensId, sceneType: obj.sceneImageType });
  });

  let currentScene = getSceneType("", store.sceneType);

  for (const object of idArray) {
    if (object.lensId === id && object.sceneType === currentScene.sceneType) {
      saveBtnStatus.value = "saved";
      return;
    }
  }

  saveBtnStatus.value = "unsaved";
};

const canFullscreen = (function () {
  for (const key of [
    "exitFullscreen",
    "webkitExitFullscreen",
    "webkitCancelFullScreen",
    "mozCancelFullScreen",
    "msExitFullscreen",
  ]) {
    if (key in document) {
      return true;
    }
  }
  return false;
})();

const requestSceneFullscreen = () => {
  if (isFullScreen.value) {
    document.exitFullscreen();
  } else {
    requestFullScreen(sliderContainer.value);
  }
};

const handleFullscreen = () => {
  if (document.fullscreenElement) {
    isFullScreen.value = true;
  } else {
    isFullScreen.value = false;
  }
};

onMounted(async () => {
  await store.fetchData();
  store.setSelectedVideo(
    store.defaultMobileVideoId ||
      process.env.VUE_APP_DEFALULT_LENS.toLowerCase()
  );
  store.addHeightOntopAndBottom(viewport);

  document.addEventListener("fullscreenchange", handleFullscreen);

  if (canFullscreen) {
    canFullScreen.value = true;
  } else {
    canFullScreen.value = false;
  }
});

onUnmounted(() => {
  store.SelectedVideo = null;
  store.SelectedFocalLength = null;
  document.removeEventListener("fullscreenchange", handleFullscreen);
});

function getLinkId(name) {
  // Convert to lowercase and replace spaces with hyphens
  return name.toLowerCase().replace(/\s+/g, "-");
}

function setSelection(lens) {
  store.setSelectedVideo(lens.replace(/\s+/g, "-").toLowerCase());
}

const uniqueLenses = (lensCategory) => {
  if (lensCategory) {
    const uniqueLenses = {};

    // Iterate through lenses in the category
    lensCategory.forEach((lens) => {
      const name = lens.name;
      const isNew = lens.isNew;

      // If lens name is not already in uniqueLenses or isNew
      if (!uniqueLenses[name] || isNew) {
        uniqueLenses[name] = isNew;
      }
    });

    // Convert uniqueLenses object to an array of objects
    const uniqueLensArray = Object.keys(uniqueLenses).map((name) => ({
      name,
      isNew: uniqueLenses[name],
    }));

    return uniqueLensArray;
  }
  return [];
};

function setFocalLengthChange(event) {
  const selected = event.target.value;
  store.setSelectedFocalLength(selected);
}
function setTransmissionChange(event) {
  const selected = event.target.value;
  store.setSelectedTransmission(selected);
}

function reloadTransmission() {
  store.SelectedVideoDetails.sort((a, b) => {
    const focalLengthA = a.focalLength
      ? parseFloat(a.focalLength.replace("mm", ""))
      : 0;
    const focalLengthB = b.focalLength
      ? parseFloat(b.focalLength.replace("mm", ""))
      : 0;
    const transmissionA = a.transmission
      ? parseFloat(a.transmission.replace("T", ""))
      : 0;
    const transmissionB = b.transmission
      ? parseFloat(b.transmission.replace("T", ""))
      : 0;
    const focalDiff = focalLengthA - focalLengthB;
    if (focalDiff !== 0) {
      return focalDiff;
    }
    return transmissionA - transmissionB;
  });
  const transmissonOptions = store.SelectedVideoDetails.filter(
    (s) => s.focalLength === store.SelectedFocalLength
  );

  // delete previous transmissions and set a new one
  const transmissionElement = document.getElementById("transmission");

  while (transmissionElement.options.length > 0) {
    transmissionElement.remove(0);
  }

  store.setSelectedTransmission(transmissonOptions[0]?.transmission);

  transmissonOptions?.forEach((option) => {
    const optionElement = document.createElement("option");
    optionElement.value = option.transmission;
    optionElement.text = option.transmission;

    // optionElement.selected =
    //   option.transmission === selectedTransmission ? "selected" : "";
    transmissionElement.appendChild(optionElement);
  });
  transmissionElement.style.display = "block";
}

const loadSceneBg = async () => {
  sliderLoading.value = true;
  await store.loadMobileScenes(sceneLensRef, sceneBackgroundRef);
  sliderLoading.value = false;
};

function setSceneType(scene) {
  if (!ENABLE_SCENE_CHANGE) return;

  store.setSceneType(scene);
}

watch(sceneWishlist, () => {
  generateSaveIcon();
});

watch(singleVideoDetail, () => {
  generateSaveIcon();

  const lensCategoryName = store.data.find(
    (lens) => lens.id === singleVideoDetail.value?.lensCategoryId
  )?.name;

  if (lensCategoryName) {
    singleVideoDetail.value.lensCategoryName = lensCategoryName;
  }

  loadSceneBg();
});

watch(SelectedVideo, () => {
  store.setSelectedVideoDetails();

  const listItem = document.getElementById(
    store.SelectedVideo.toLowerCase().replace(/\s+/g, "-")
  );

  if (store.searchIsActive) {
    const navEntities = document.querySelectorAll(".nav-entity");
    navEntities.forEach((item) => {
      item.style.transform = "rotateX(0deg)";
    });
  }

  const lensesItem = store.SelectedVideoDetails.find(
    (s) =>
      s.focalLength === store.SelectedFocalLength &&
      s.transmission === store.SelectedTransmission
  );

  if (lensesItem) {
    //store.setVideoPathOnChange(lensesItem, videoRef);
    singleVideoDetail.value = lensesItem;
    store.generateIcon(lensesItem.icon, iconRef);
  }

  store.scrollToItem(listItem);
  store.generateDropDown(" ", focalLengthRef, transmissionRef);
  store.setSelectedFocalLength(store.SelectedVideoDetails[0].focalLength);
  store.setSelectedTransmission(store.SelectedVideoDetails[0].transmission);
});

watch(SelectedFocalLength, () => {
  store.setSelectedVideoDetails();
  reloadTransmission();
  const lensesItem = store.SelectedVideoDetails.find(
    (s) =>
      s.focalLength === store.SelectedFocalLength &&
      s.transmission === store.SelectedTransmission
  );

  singleVideoDetail.value = lensesItem;
  store.generateIcon(lensesItem.icon, iconRef);
  //store.setVideoPathOnChange(lensesItem, videoRef);
});

watch(SelectedTransmission, () => {
  store.setSelectedVideoDetails();

  const lensesItem = store.SelectedVideoDetails.find(
    (s) =>
      s.focalLength === store.SelectedFocalLength &&
      s.transmission === store.SelectedTransmission
  );
  store.generateIcon(lensesItem.icon, iconRef);
  //store.setVideoPathOnChange(lensesItem, videoRef);
  singleVideoDetail.value = lensesItem;
});

watch(sceneType, () => {
  store.loadMobileScenes(sceneLensRef, sceneBackgroundRef);
  generateSaveIcon();
});
</script>

<style scoped>
.video-control-mobile {
  bottom: 15px;
  padding: 0 10px;
  z-index: 3;
  width: 100%;
  flex-direction: column-reverse;
  align-items: end;
  justify-content: space-between;
  gap: 5px;
  min-height: 34px;
}

.video-control-mobile img {
  width: 22px;
  height: 26px;
  padding: 8px 0 4px 8px;
}

.lensesList {
  margin-right: 100px;
}

.fullscreen-img {
  opacity: 0.5;
}

.video-full-screen {
  position: absolute;
  top: 0;
}

@media screen and (max-width: 640px) {
  .lensesList {
    margin-left: 0;
    margin-right: 20px;
  }
}

.lenslist-container {
  display: flex;
  justify-content: center;
}

.video-timeline-bar {
  width: 100%;
  height: 2px;
  background: rgba(255, 255, 255, 0.1);
  position: relative;
  border-radius: 10px;
}

.video-timeline-progress {
  position: absolute;
  left: 0;
  width: 0%;
  height: 100%;
  background: rgba(128, 128, 128, 0.5);
  border-radius: 10px;
}

video::-webkit-media-controls-play-button,
video::-webkit-media-controls-pause-button {
  display: block !important;
}

.nav-title {
  margin-top: 0;
}

.mobile-details-container {
  margin: 0 auto;
  padding: 0 20px;
  max-width: 400px;
}

.new-badge {
  display: inline-block;
  background-color: #0f52ba;
  color: #fff;
  padding: 0.2rem 0.5rem;
  border-radius: 0.3rem;
  font-size: 0.7rem;
  margin-left: 0.5rem;
}

.scene-title {
  text-align: center;
  color: rgb(190, 190, 190);
  font-size: 22px;
  text-transform: uppercase;
  font-weight: 500;
}

.slider-container {
  border-radius: 0;

  display: flex;
  align-items: center;
}

.scene-fullscreen {
  bottom: 10px;
  right: 10px;
  opacity: 0.8;
}
</style>
