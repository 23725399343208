<template>
  <div class="tertiary-container">
    <div class="info-box">
      <div class="info-contents">
        <img src="@/assets/img/premium-icon.svg" class="info-icon" />
        <h4 class="gradient-text info-head">Payment Successful</h4>
        <p>
          You have successfully subscribed to the pro version of CINEFLARES. Now
          you have access to all the pro features and our full PRO library.
        </p>
        <RouterLink class="info-link" to="/">Explore Flares</RouterLink>
      </div>
    </div>
  </div>
</template>
