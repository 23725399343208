<!-- eslint-disable vue/multi-word-component-names -->

<template>
  <header ref="headerRef" :class="{ 'non-transparent-bg': transparentHeader }">
    <AlertBox
      v-if="showComingSoonAlert"
      @closeAlert="showComingSoonAlert = false"
    >
      <div class="premium-upgrade-box">
        <img src="../assets/img/premium-icon.svg" alt="" />
        <h4 class="gradient-text">Coming Soon</h4>
        <p>We will notify you once the pro version is available.</p>
        <!--<RouterLink class="pricing-link" to="/pricing">View Plans</RouterLink>-->
      </div>
    </AlertBox>
    <div class="header__container">
      <div class="column left"></div>
      <div class="column header-middle">
        <ul class="header__nav">
          <li v-if="userStore.isLoggedInValue">
            <router-link to="/">FLARES</router-link>
          </li>
          <li v-if="userStore.isLoggedInValue">
            <router-link to="/selects">SELECTS</router-link>
          </li>

          <li v-if="userStore.isLoggedInValue && userStore.userDetails?.isPremiumUser">
            <router-link to="/scene">SCENE</router-link>
          </li>

          <li
            v-if="
              userStore.isLoggedInValue && userStore.userDetails?.isPremiumUser
            "
          >
            <router-link to="/browse">BROWSE</router-link>
          </li>
          <li>
            <router-link to="/about">ABOUT</router-link>
          </li>
          <li><router-link to="/news">NEWS</router-link></li>
          <li v-if="!userStore.isLoggedInValue">
            <router-link to="/pricing">PRICING</router-link>
          </li>

          <li
            v-if="
              userStore?.userDetails &&
              !userStore?.userDetails?.isPremiumUser &&
              userStore.isLoggedInValue
            "
          >
            <a
              @click.prevent="handlePaymentRouting"
              href=""
              class="nav-gradient"
              >SIGN UP - PRO</a
            >
          </li>

          <div style="display: flex" v-if="!userStore.isLoggedInValue">
            <li>
              <router-link to="/signup" class="nav-gradient"
                >SIGN UP</router-link
              >
            </li>
            <li>
              <router-link to="/login">LOGIN</router-link>
            </li>
          </div>
        </ul>

        <ul class="header__social">
          <li>
            <a href="https://www.instagram.com/cinelensflares/" target="_blank">
              <img src="../assets/img/ig.png" alt="Instagram" />
            </a>
          </li>
          <li>
            <a
              href="https://www.youtube.com/channel/UCnlZIlwyPUALz5ja6sYUU2g/featured"
              target="_blank"
            >
              <img src="../assets/img/youtube.png" alt="Youtube" />
            </a>
          </li>
          <li>
            <a href="https://vimeo.com/user160332965" target="_blank">
              <img src="../assets/img/vimeo.png" alt="Vimeo" />
            </a>
          </li>
        </ul>
      </div>
      <div class="column right">
        <div
          class="hamburger pointer"
          v-if="userStore.isLoggedInValue"
          @click="displaySidebar"
        >
          <img src="../assets/img/hamburger.svg" width="20" />
        </div>
      </div>
    </div>
    <Transition name="sidebar">
      <div id="webSideBar" @click="hideNavBar" v-if="showSidebar">
        <sidebar-menu
          @item-click="onItemClick"
          :menu="menu"
          width="200px"
          :rtl="true"
          :hideToggle="true"
        />
      </div>
    </Transition>
  </header>
</template>

<script setup>
import { useUsersStore } from "@/stores/Users";
import { computed, ref } from "vue";
import { useRouter } from "vue-router";
import AlertBox from "./AlertBox.vue";
import config from "@/config/config";

const headerRef = ref(null);
const transparentHeader = ref(true);
const currentRouteName = ref("");

const router = useRouter();
const userStore = useUsersStore();

const showSidebar = ref(false);
const showComingSoonAlert = ref(false);
currentRouteName.value = computed(() => router.currentRoute.value.name);
const handlePaymentRouting = () => {
  if (!config.enablePayment) return (showComingSoonAlert.value = true);

  router.push("/pricing");
};

const displaySidebar = () => {
  showSidebar.value = true;
};

const hideNavBar = (e) => {
  if (e.target.classList.contains("vsm--scroll")) return;

  showSidebar.value = false;
};

const onItemClick = async (event, item) => {
  if (item.title !== "LOGOUT") return;

  await userStore.logOut();
  router.push("/login");
};

const menu = [
  {
    title: "ACCOUNT",
    class: "web-menu-item",
    icon: "fa fa-user",
    href: "/account",
  },
  {
    title: "LOGOUT",
    class: "web-menu-item",
    icon: "fa fa-sign-out",
  },
];

const checkScrollDistance = () => {
  if (window.scrollY > 0) {
    transparentHeader.value = false;
  } else {
    transparentHeader.value = true;
  }
};

window.addEventListener("scroll", () => {
  checkScrollDistance();
});
</script>

<style scoped>
header {
  background-color: rgba(35, 35, 35);
  color: #fff;
  padding: 0px;
  text-align: center;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 10;
  transition: all 0.2s;
}

header.non-transparent-bg {
  background-color: rgba(35, 35, 35, 0.5);
}

.header__container {
  display: flex;
  align-items: center;
  height: 35px;
}

li.router-link-active,
li router-link-exact-active {
  background-color: blue;
}

.header__row {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
}

.header__nav {
  display: flex;
  align-items: center;
  list-style-type: none;
}

.header__nav li a {
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.13em;
  text-decoration: none;
  color: #fff;
  font-family: "figtree-semibold";
}

.header__social li a:hover {
  color: #fff;
}

.header__nav li.active a {
  color: rgb(45, 120, 250);
  font-family: "figtree-regular";
}

.header__nav a.router-link-exact-active {
  color: rgb(45, 120, 250);
  font-family: "figtree-regular";
  font-weight: bold;
}

.header__nav li:not(:last-child) {
  margin-right: 14px;
}

.header__nav,
.header__social {
  display: flex;
  align-items: center;
  list-style-type: none;
}

.header__social li a img {
  width: 25px;
  padding: 0 5px;
}

.header__social li a:hover {
  color: #fff;
}

.header__social img {
  transition: filter 0.3s ease-in-out;
}

.header__social img:hover {
  transform: scale(1.5);
  transition: transform 0.3s ease-in-out 0s;
}

.header__nav li:hover {
  transform: scale(1.15);
  transition: transform 0.3s ease-in-out 0s;
}

h1 {
  margin: 0;
}

.column {
  flex: 1;
}

.right {
  justify-content: flex-end;
  padding-top: 0;
}

.header-middle {
  display: flex;
  flex-direction: row;
  flex: 3;
  padding: 0;
  justify-content: right;
}

.hamburger {
  display: flex;
  justify-content: flex-end;
  margin-right: 1rem;
}

#webSideBar {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
}

.v-sidebar-menu {
  background: rgba(15, 15, 15, 0.8) !important;
  padding-top: 50px !important;
}

.v-sidebar-menu .vsm--link:not(:last-child) {
  border-bottom: 2px solid white;
}
</style>
