export const def_notableFilms = [{ name: null, link: null }];

export const lensEraChoices = [
  { id: "modern", name: "Modern" },
  { id: "vintage", name: "Vintage" },
];

export const flareTypeChoices = [
  { id: "streak", name: "Streak" },
  { id: "spherical-spot", name: "Spherical Spot" },
  { id: "oval", name: "Oval" },
  { id: "veiling", name: "Veiling" },
  { id: "rainbow", name: "Rainbow" },
  { id: "starburst", name: "Starburst" },
  { id: "ghost", name: "Ghost" },
  { id: "narcissism", name: "Narcissism" },
  { id: "x-flare", name: "X-Flare" },
  { id: "caustic", name: "Caustic" },
  { id: "ring", name: "Ring" },
  { id: "anamorphic-spot", name: "Anamorphic Spot" },
  { id: "irish-blade-reflection", name: "Irish Blade Reflection" },
];

export const flareColorChoices = [
  { id: "white", name: "White" },
  { id: "blue", name: "Blue" },
  { id: "red", name: "Red" },
  { id: "purple", name: "Purple" },
  { id: "cyan", name: "Cyan" },
  { id: "amber", name: "Amber" },
  { id: "yellow", name: "Yellow" },
  { id: "green", name: "Green" },
];

export const flareIntensityChoices = [
  { id: "strong", name: "Strong" },
  { id: "average", name: "Average" },
  { id: "low", name: "Low" },
];

export const flareFOVChoices = [
  { id: "wide", name: "Wide" },
  { id: "normal", name: "Normal" },
  { id: "long", name: "Long" },
];
